import { Component, OnInit, OnDestroy, inject } from "@angular/core";
import { Location } from "@angular/common";
import { Subscription } from "rxjs";
import { TargetsService } from "../targets.service";
import _ from "lodash";
import { MediaconnectEntitlementTarget, TargetApiType } from "../../channels/channel";
import { UntypedFormControl, Validators } from "@angular/forms";
import { Constants } from "src/app/constants/constants";
import { ActivatedRoute, Router } from "@angular/router";
import { urlBuilder } from "@zixi/shared-utils";
import { SharedService } from "src/app/services/shared.service";
import { TitleService } from "src/app/services/title.service";

import { ChannelsService } from "../../channels/channels.service";
import { MediaConnectFlow } from "src/app/models/shared";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { ModalService } from "src/app/components/shared/modals/modal.service";

@Component({
    selector: "app-target-entitlement-form",
    templateUrl: "./target-entitlement-form.component.html"
})
export class TargetEntitlementFormComponent implements OnInit, OnDestroy {
    private titleService = inject(TitleService);
    private sharedService = inject(SharedService);
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private location = inject(Location);
    private ts = inject(TargetsService);
    private cs = inject(ChannelsService);
    private mixpanelService = inject(MixpanelService);
    private modalService = inject(ModalService);
    target: MediaconnectEntitlementTarget;
    existingTarget: MediaconnectEntitlementTarget;
    targetId: number;
    targetNames: string[];
    targetName: string;
    action: string;

    type: string;
    subtype: string;
    id: string;

    loading = true;
    saving = false;

    isEdit = false;
    isClone = false;

    startDisabled = false;

    channels: MediaConnectFlow[];
    constants = Constants;

    private targetsSubscription: Subscription;
    private channelsSubscription: Subscription;
    private restartConfirmed = false;

    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.entitlement_name),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);
    dataPrecentControl = new UntypedFormControl(0, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(3),
        Validators.pattern(Constants.validators.zero_to_hundred)
    ]);
    subscriberIdControl = new UntypedFormControl("", [
        Validators.required,
        Validators.pattern(Constants.validators.aws_subscriber_account_id)
    ]);

    mediaconnect_flow_id: number | undefined;

    prepForm() {
        if (!this.target && this.action) {
            return;
        }

        if (this.id) {
            this.mediaconnect_flow_id = parseInt(this.id);
        }

        if (this.action) {
            this.tagsControl.setValue(this.target.resourceTags);
            this.subscriberIdControl.setValue(this.target.subscriber_id);
            this.mediaconnect_flow_id = this.target.mediaconnect_flow_id;

            if (this.isEdit) this.nameControl.setValue(this.target.name);

            if (this.isClone) {
                this.target.name = "";
                this.target.muted = this.target.active_mute ? 1 : 0;
            }
        }

        if (!this.target && !this.isClone && !this.isEdit) {
            this.resetForm();
        }

        // Set Title
        this.titleService.setTitle("TARGET", this.action, this.target);
    }

    resetForm() {
        this.tagsControl.setValue([]);
        this.nameControl.setValue(null);

        this.target = new MediaconnectEntitlementTarget();

        // initial values
        this.target.subscriber_fee_precent = 0;
    }

    async ngOnInit() {
        const params = this.route.snapshot.params;
        if (params.targetId) this.targetId = urlBuilder.decode(params.targetId);
        this.targetName = params.name;
        this.action = params.action;
        this.type = params.type;
        this.subtype = params.subtype;
        this.id = params.id;

        if (this.action === "edit") this.isEdit = true;
        if (this.action === "clone") this.isClone = true;

        let anyTarget = null;

        if (this.targetId) {
            anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.Entitlement));

            // Check if target found in cache, if not get targets and target
            if (this.sharedService.isEmptyObject(anyTarget)) {
                await this.ts.refreshTargets(TargetApiType.Entitlement).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.Entitlement));
                await this.ts.refreshTarget(TargetApiType.Entitlement, anyTarget.objId, true).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.Entitlement));
            } else if (!anyTarget.target.hasFullDetails) {
                await this.ts.refreshTarget(TargetApiType.Entitlement, anyTarget.objId, true).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.Entitlement));
            }
        }

        if (anyTarget && anyTarget.type === "entitlement") {
            this.target = anyTarget.target;
            this.existingTarget = _.cloneDeep(this.target);
        }

        this.loading = false;

        //  Channels
        this.cs.getMediaConnectFlows(true);
        this.channelsSubscription = this.cs.mediaconnectFlows.subscribe((channels: MediaConnectFlow[]) => {
            this.channels = channels;
        });

        this.ts.getAllTargets();

        this.targetsSubscription = this.ts.targets.subscribe(targets => {
            this.targetNames = _.map(
                _.filter(targets, t => t.apiType === TargetApiType.Entitlement),
                "target.name"
            );

            if (this.isEdit) {
                this.targetNames = _.without(this.targetNames, this.targetName);
            }
        });
        this.prepForm();
    }

    ngOnDestroy() {
        this.targetsSubscription?.unsubscribe();
        this.channelsSubscription?.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            name: this.nameControl.value,
            resource_tag_ids: _.map(this.tagsControl.value, "id"),
            alerting_profile_id: this.target.alertingProfile.id,
            mediaconnect_flow_id: this.mediaconnect_flow_id,
            muted: this.isEdit ? this.target.muted : !!this.target.muted,
            is_enabled:
                !this.isEdit && this.startDisabled
                    ? 0
                    : !this.isEdit && !this.startDisabled
                    ? 1
                    : this.target.is_enabled,
            subscriber_id: this.subscriberIdControl.value,
            subscriber_fee_precent: this.dataPrecentControl.value
        };

        if (this.isEdit) {
            const changedData = this.sharedService.getZixiObjDiff(model, this.existingTarget, []);

            changedData.restart_confirmed = this.restartConfirmed;

            const result = await this.ts.updateTarget(this.target, changedData);

            if (typeof result !== "boolean") {
                this.saving = false;
                this.mixpanelService.sendEvent("update entitlement target", {
                    updated: Object.keys(changedData)
                });
                this.router.navigate(
                    urlBuilder.getRegularTargetUrl(result.objId, Constants.urls.targetTypes.entitlement, model.name)
                );
            } else if (result === true) {
                // restart notice
                await this.modalService.confirm(
                    "SAVE_RESTART",
                    "TARGET",
                    async () => {
                        this.restartConfirmed = true;
                        this.onSubmit();
                    },
                    this.target.name
                );

                this.mixpanelService.sendEvent("update & restart entitlement target", {
                    updated: Object.keys(changedData)
                });
            } else this.saving = false;
        } else {
            const result = await this.ts.addTarget(model, TargetApiType.Entitlement);
            if (result) {
                this.saving = false;
                this.mixpanelService.sendEvent("create entitlement target");
                this.router.navigate(
                    urlBuilder.getRegularTargetUrl(result.id, Constants.urls.targetTypes.entitlement, model.name)
                );
            } else this.saving = false;
        }
    }

    cancel() {
        if (this.isEdit || this.isClone)
            this.router.navigate(
                urlBuilder.getRegularTargetUrl(this.targetId, Constants.urls.targetTypes.entitlement, this.targetName)
            );
        else this.router.navigate([Constants.urls.targets]);
    }

    back() {
        this.location.back();
    }
}
