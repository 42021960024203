import { Component, OnInit, OnDestroy, inject } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { UsersService } from "../../account-management/users/users.service";
import { SharedService } from "../../../services/shared.service";
import { TargetsService } from "../targets.service";
import { TranscodingProfilesService } from "../../transcoding-profiles/transcoding-profiles.service";

import { MediaLiveChannel, MediaLiveHttpTarget, TargetApiType } from "../../channels/channel";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "../../../services/mixpanel.service";
import { TitleService } from "../../../services/title.service";
import { TranscodingProfile } from "../../transcoding-profiles/transcoding-profile";

import { Source } from "src/app/models/shared";
import { UntypedFormControl, Validators } from "@angular/forms";
import { urlBuilder } from "@zixi/shared-utils";
interface BitrateOption {
    profile?: TranscodingProfile;
    profile_id: number;
    source?: Source;
    source_id: number;
    name: string;
    kbps: number;
    gpu_id?: number;
}

@Component({
    selector: "app-target-medialive-http-form",
    templateUrl: "./target-medialive-http-form.component.html"
})
export class TargetMediaLiveHttpFormComponent implements OnInit, OnDestroy {
    target: MediaLiveHttpTarget;
    existingTarget: MediaLiveHttpTarget;
    targetId: number;
    targetName: string;
    targetNames: string[];
    action: string;
    type: string;
    subtype: string;
    id: string;
    ingestURLs: string[];

    showAdvanced = false;
    loading = true;
    saving = false;
    subtitlesWindowSeconds = 30;
    submitted = false;
    minLength = 2;
    isEdit = false;
    isClone = false;
    startDisabled = false;
    constants = Constants;
    multiGpu = false;
    page = 1;
    pageSize = 10;
    private targetsSubscription: Subscription;

    applicationHost: string;
    mediastoreRegions = Constants.mediastoreRegions;
    accountsLoading: boolean;
    selectedChannelID: number;
    mode: string;
    selectedBitrates: BitrateOption[] = [];
    profiles: TranscodingProfile[] = [];
    profilesLoading = true;

    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.source_name),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private sharedService = inject(SharedService);
    private userService = inject(UsersService);
    private modalService = inject(ModalService);
    private ts = inject(TargetsService);
    private transcodingProfilesService = inject(TranscodingProfilesService);
    private mixpanelService = inject(MixpanelService);
    private titleService = inject(TitleService);
    private location = inject(Location);

    prepForm() {
        if (!this.target && this.action) {
            return;
        }

        if (this.action) {
            this.tagsControl.setValue(this.target.resourceTags);
            if (this.isEdit) {
                this.nameControl.setValue(this.target.name);
            } else if (this.isClone) {
                this.target.name = "";
                this.target.muted = this.target.active_mute ? 1 : 0;
            }
            this.selectedChannelID = this.selectedChannelID ?? this.target.mediaconnect_flow_id;

            if (this.target) {
                this.ingestURLs = _.without(this.ingestURLs, this.target.ingest_url);
                if (this.target.transcodingProfiles) {
                    this.selectedBitrates = this.target.transcodingProfiles.map(p => ({
                        profile_id: p.id,
                        name: p.name,
                        profile: p,
                        kbps: 0,
                        source_id: 0
                    }));
                }
            }
            if (this.selectedBitrates) {
                this.profiles = _.filter(this.profiles, p => {
                    return !_.find(this.selectedBitrates, b => {
                        return b.profile_id === p.id;
                    });
                });
            }
        }

        if (!this.target && !this.isClone && !this.isEdit) {
            this.resetForm();
        }

        // Set Title
        this.titleService.setTitle("TARGET", this.action, this.target);
    }

    resetForm() {
        this.tagsControl.setValue([]);
        this.nameControl.setValue(null);

        // Channel
        this.target = new MediaLiveHttpTarget();
        this.target.type = "medialive_http";
        this.target.destination = "s3";
        this.selectedBitrates = [];
    }

    async ngOnInit() {
        const params = this.route.snapshot.params;
        if (params.targetId) this.targetId = urlBuilder.decode(params.targetId);
        this.targetName = params.name;
        this.action = params.action;
        this.type = params.type;
        this.subtype = params.subtype;
        this.id = params.id;

        if (this.action === "edit") this.isEdit = true;
        if (this.action === "clone") this.isClone = true;

        let anyTarget = null;

        if (this.targetId) {
            anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.MediaLiveHttp));

            // Check if target found in cache, if not get targets and target
            if (this.sharedService.isEmptyObject(anyTarget)) {
                await this.ts.refreshTargets(TargetApiType.MediaLiveHttp).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.MediaLiveHttp));
                await this.ts.refreshTarget(TargetApiType.MediaLiveHttp, anyTarget.objId, true).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.MediaLiveHttp));
            } else if (!anyTarget.target.hasFullDetails) {
                await this.ts.refreshTarget(TargetApiType.MediaLiveHttp, anyTarget.objId, true).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.MediaLiveHttp));
            }
        }

        if (anyTarget && anyTarget.target instanceof MediaLiveHttpTarget) {
            this.target = anyTarget.target;
            this.existingTarget = _.cloneDeep(this.target);
        }

        this.loading = false;

        if (this.type === "channel" && this.subtype && this.id) {
            if (this.subtype === "medialive") {
                this.selectedChannelID = parseInt(this.id, 10);
            }
        }

        this.ts.getAllTargets();

        this.targetsSubscription = this.ts.targets.subscribe(targets => {
            this.targetNames = _.map(
                _.filter(targets, t => t.apiType === TargetApiType.MediaLiveHttp),
                "target.name"
            );
            this.ingestURLs = _.map(targets, "target.ingest_url");

            if (this.isEdit) {
                this.targetNames = _.without(this.targetNames, this.targetName);
            }
        });

        this.userService.user.pipe(take(1)).subscribe(u => (this.applicationHost = u.application_host));

        this.prepForm();
        this.profilesLoading = true;
        this.accountsLoading = true;
        this.accountsLoading = false;

        // Transcoding Profiles
        this.transcodingProfilesService.refreshTranscodingProfiles();
        this.transcodingProfilesService.transcodingProfiles
            .pipe(take(1))
            .subscribe((profiles: TranscodingProfile[]) => {
                this.profiles = profiles;
                this.profiles = _.filter(profiles, p => {
                    return !_.find(this.selectedBitrates, b => {
                        return b.profile_id === p.id;
                    });
                });
                this.profilesLoading = false;
            });
    }

    ngOnDestroy() {
        this.targetsSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;
        if (!this.multiGpu) this.selectedBitrates.forEach(b => (b.gpu_id = null));
        const model = {
            destination: this.target.destination,

            name: this.nameControl.value,

            resource_tag_ids: _.map(this.tagsControl.value, "id"),
            alerting_profile_id: this.target.alertingProfile.id,
            ignore_certificate: this.target.ignore_certificate,
            ingest_url: this.target.ingest_url,
            medialive_channel_id: this.selectedChannelID ?? null,
            muted: this.target.muted,
            is_enabled:
                !this.isEdit && this.startDisabled
                    ? 0
                    : !this.isEdit && !this.startDisabled
                    ? 1
                    : this.target.is_enabled,
            bitrates: this.selectedBitrates.map(bitrate => Object.assign(_.pick(bitrate, ["profile_id"])))
        };

        if (this.isEdit) {
            const objects = { resource_tag_ids: { objectsKey: "resourceTags", valuePath: "id" } };
            const changedData = this.sharedService.getZixiObjDiff(model, this.existingTarget, [], objects);
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedTarget = await this.ts.updateTarget(this.existingTarget, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedTarget;
                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "TARGET",
                        async () => {
                            const updateAndRestartTarget = await this.ts.updateTarget(this.target, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartTarget) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart medialive_http target", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(
                                    urlBuilder.getRegularTargetUrl(
                                        this.targetId,
                                        Constants.urls.targetTypes.medialive_http,
                                        model.name
                                    )
                                );
                            } else this.saving = false;
                        },
                        this.target.name
                    );
                    this.saving = false;
                } else if (updatedTarget) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update & restart medialive_http target", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(
                        urlBuilder.getRegularTargetUrl(
                            this.targetId,
                            Constants.urls.targetTypes.medialive_http,
                            model.name
                        )
                    );
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate(
                    urlBuilder.getRegularTargetUrl(this.targetId, Constants.urls.targetTypes.medialive_http, model.name)
                );
            }
        } else {
            const result = await this.ts.addTarget(model, TargetApiType.MediaLiveHttp);
            if (result) {
                this.saving = false;
                this.mixpanelService.sendEvent("create medialive_http target");
                this.router.navigate(
                    urlBuilder.getRegularTargetUrl(result.id, Constants.urls.targetTypes.medialive_http, model.name)
                );
            } else this.saving = false;
        }
    }

    compareBitrates(existingTarget, model) {
        const reducedOriginal = _.map(existingTarget.bitrates, bitrate => {
            return _.pick(bitrate, "profile_id", "source_id", "name", "kbps", "gpu_id");
        });
        if (_.isEqual(reducedOriginal, model.bitrates)) {
            return false;
        } else {
            return model.bitrates;
        }
    }

    cancel() {
        if (this.isEdit || this.isClone)
            this.router.navigate(
                urlBuilder.getRegularTargetUrl(
                    this.targetId,
                    Constants.urls.targetTypes.medialive_http,
                    this.targetName
                )
            );
        else this.router.navigate([Constants.urls.targets]);
    }

    back() {
        this.location.back();
    }

    profileKbps(profile: TranscodingProfile) {
        if (profile.keep_video || profile.keep_audio) return null;
        const audioBitrate = profile.do_audio ? profile.audio_bitrate + 8 : 0;
        const videoBitrate = profile.do_video ? profile.bitrate_avg + 25 : 0;
        return Math.floor((videoBitrate + audioBitrate) * 0.1021) * 10 || null;
    }

    selectTranscodedBitrate(profile: TranscodingProfile) {
        this.selectedBitrates.push({
            profile,
            profile_id: profile.id,
            source: null,
            source_id: null,
            name: profile.name,
            kbps: this.profileKbps(profile),
            gpu_id: 0
        });
        this.profiles = this.profiles.filter(p => {
            return p.id !== profile.id;
        });
    }

    deselectTranscodedBitrate(bitrate: BitrateOption) {
        this.profiles.push(bitrate.profile);
        this.profiles = this.sharedService.sort(this.profiles || [], "profile", "asc");
        this.selectedBitrates = this.selectedBitrates.filter(b => {
            return b.profile_id !== bitrate.profile_id;
        });
    }
}
