import { Component, OnInit, OnDestroy, inject } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { SharedService } from "src/app/services/shared.service";
import { TargetsService } from "../targets.service";
import { ChannelsService } from "../../channels/channels.service";
import { MediaConnectFlow } from "src/app/models/shared";
import { MediaconnectJPEGXSTarget, TargetApiType } from "../../channels/channel";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "../../../services/title.service";
import {
    FullMediaconnectMediaStream,
    targetUpdated
} from "../../../components/shared/zx-mediaconnect-mediastream/zx-mediaconnect-mediastream.component";
import { FullVPCInterface } from "../../../components/shared/zx-mediaconnect-vpc/zx-mediaconnect-vpc.component";
import { AmazonAwsService } from "../../configuration/amazon-aws/amazon-aws.service";
import {
    AmazonAWS,
    AmazonAWSAvailabilityZone,
    AmazonAWSIAMRole,
    AmazonAWSSecurityGroup,
    AmazonAWSSubnet
} from "../../configuration/amazon-aws/amazon-aws";
import { UntypedFormControl, Validators } from "@angular/forms";
import { urlBuilder } from "@zixi/shared-utils";
@Component({
    selector: "app-target-jpegxs-form",
    templateUrl: "./target-jpegxs-form.component.html"
})
export class TargetJPEGXSFormComponent implements OnInit, OnDestroy {
    target: MediaconnectJPEGXSTarget;
    targetId: number;
    targetName: string;
    targetNames: string[];
    existingTarget: MediaconnectJPEGXSTarget;
    action: string;

    type: string;
    subtype: string;
    id: string;

    ingestURLs: string[];

    showAdvanced = false;
    loading = true;
    saving = false;

    submitted = false;
    minLength = 2;
    isEdit = false;
    isClone = false;
    startDisabled = false;
    constants = Constants;

    channel: MediaConnectFlow;
    channels: MediaConnectFlow[];
    selectedChannelID: number = null;

    channelRegion: string;
    mediaStreams: FullMediaconnectMediaStream[] = [];
    vpc: FullVPCInterface[] = [];

    awsAccounts: AmazonAWS[] = [];
    awsAccountRegionsLoading = false;
    awsRegions: { id: string; name: string }[] = [];

    awsAccountZonesLoading = false;
    awsZones: AmazonAWSAvailabilityZone[] = [];
    awsSubnets: (AmazonAWSSubnet & { name: string })[] = [];
    awsSubnetOptions: (AmazonAWSSubnet & { name: string })[] = [];

    awsIAMRolesLoading = false;
    awsIAMRoles: AmazonAWSIAMRole[] = [];
    awsSecurityGroups: AmazonAWSSecurityGroup[] = [];

    VPCSelectionProblem = false;
    mediaStreamSelectionProblem = false;
    mediaStreamPorts: number[] = [];

    private targetsSubscription: Subscription;
    private channelsSubscription: Subscription;
    private awsAccountsSubscription: Subscription;

    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.source_name),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private sharedService = inject(SharedService);
    private modalService = inject(ModalService);
    private cs = inject(ChannelsService);
    private ts = inject(TargetsService);
    private aas = inject(AmazonAwsService);
    private mixpanelService = inject(MixpanelService);
    private titleService = inject(TitleService);
    private location = inject(Location);

    prepForm() {
        if (!this.target && this.action) {
            return;
        }

        if (this.action) {
            this.tagsControl.setValue(this.target.resourceTags);
            if (this.isEdit) this.nameControl.setValue(this.target.name);

            if (this.isClone) {
                this.target.name = "";
                this.target.muted = this.target.active_mute ? 1 : 0;
            }
        }

        if (!this.target && !this.isClone && !this.isEdit) {
            this.resetForm();
        }

        if (this.target && this.selectedChannelID && this.channels) {
            this.channel = this.channels.find(c => c.id === this.selectedChannelID);
            this.flowSelected();
            for (const vpc of this.target.vpc ?? []) {
                const theVPC = this.vpc.find(v => v.name === vpc.name);
                if (theVPC) {
                    theVPC.inUse = true;
                    theVPC.destination_ip = vpc.destination_ip;
                }
            }

            for (const ms of this.target.mediaStreams ?? []) {
                const theStream = this.mediaStreams.find(strm => strm.name === ms.name);
                if (theStream) {
                    theStream.inUse = true;
                    theStream.port = ms.port;
                    theStream.encoding_compression = ms.encoding_compression;
                    theStream.encoding_profile = ms.encoding_profile;
                    theStream.encoding_name = ms.encoding_name;
                }
            }

            this.mediaStreamConfigChanged();
            this.VPCCompositionChanged();
        }

        // Set Title
        this.titleService.setTitle("TARGET", this.action, this.target);

        if (!this.channel) {
            this.selectedChannelID = null;
        }
    }

    resetForm() {
        this.tagsControl.setValue([]);
        this.nameControl.setValue(null);

        // Channel
        this.target = new MediaconnectJPEGXSTarget();
        this.target.type = "jpegxs";
    }

    async ngOnInit() {
        const params = this.route.snapshot.params;
        if (params.targetId) this.targetId = urlBuilder.decode(params.targetId);
        this.targetName = params.name;
        this.action = params.action;
        this.type = params.type;
        this.subtype = params.subtype;
        this.id = params.id;

        if (this.action === "edit") this.isEdit = true;
        if (this.action === "clone") this.isClone = true;

        let anyTarget = null;

        if (this.targetId) {
            anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.JPEGXS));

            // Check if target found in cache, if not get targets and target
            if (this.sharedService.isEmptyObject(anyTarget)) {
                await this.ts.refreshTargets(TargetApiType.JPEGXS).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.JPEGXS));
                await this.ts.refreshTarget(TargetApiType.JPEGXS, anyTarget.objId, true).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.JPEGXS));
            } else if (!anyTarget.target.hasFullDetails) {
                await this.ts.refreshTarget(TargetApiType.JPEGXS, anyTarget.objId, true).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.JPEGXS));
            }
        }
        this.loading = false;

        if (anyTarget && anyTarget.target instanceof MediaconnectJPEGXSTarget) {
            this.target = anyTarget.target;
            this.existingTarget = _.cloneDeep(this.target);
        }

        //  Channels
        this.cs.getMediaConnectFlows(true);
        this.channelsSubscription = this.cs.mediaconnectFlows.subscribe((channels: MediaConnectFlow[]) => {
            this.channels = channels.filter(this.filterFlows);
        });

        // AWS Accounts
        this.aas.refreshAWSAccounts(true);
        this.awsAccountsSubscription = this.aas.awsAccounts.subscribe((awsAccounts: AmazonAWS[]) => {
            this.awsAccounts = awsAccounts;
        });

        // Set selected channel from URL (E.g. add target from channel)
        if (this.type === "channel" && this.subtype === "mediaconnect" && this.id) {
            this.selectedChannelID = parseInt(this.id, 10);
        }

        this.ts.getAllTargets();

        this.targetsSubscription = this.ts.targets.subscribe(targets => {
            this.targetNames = _.map(
                _.filter(targets, t => t.apiType === TargetApiType.JPEGXS),
                "target.name"
            );
            this.ingestURLs = _.map(targets, "target.ingest_url");

            if (this.isEdit) {
                this.targetNames = _.without(this.targetNames, this.targetName);
            }
        });

        this.prepForm();
    }

    ngOnDestroy() {
        this.targetsSubscription.unsubscribe();
        this.channelsSubscription.unsubscribe();
        this.awsAccountsSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        //  Expecting these to be handled by validators, so far these are not, perhaps worth leaving
        //  the check anyway.
        const streamsInUse: FullMediaconnectMediaStream[] = !this.channel
            ? []
            : this.mediaStreams
                  .filter(ms => ms.inUse)
                  .map(strm => {
                      const { encoding_profile, encoding_compression, ...essentials } = strm;
                      encoding_profile;
                      encoding_compression;
                      if (strm.stream_type === "video") return strm;
                      return essentials;
                  });
        const vpcInUse = !this.channel ? [] : this.vpc.filter(v => v.inUse);
        const streamsWithProblems = streamsInUse.filter(
            strm =>
                !strm.port || (strm.stream_type === "video" && (!strm.encoding_profile || !strm.encoding_compression))
        );
        const vpcsWithProblems = vpcInUse.filter(vpc => !vpc.destination_ip);
        if (
            this.channel &&
            (this.VPCSelectionProblem ||
                this.mediaStreamSelectionProblem ||
                streamsWithProblems.length > 0 ||
                vpcsWithProblems.length > 0)
        ) {
            this.saving = false;
            return;
        }

        const model = {
            name: this.nameControl.value,

            resource_tag_ids: _.map(this.tagsControl.value, "id"),
            alerting_profile_id: this.target.alertingProfile.id,
            type: "jpegxs",
            mediaconnect_flow_id: this.selectedChannelID ?? null,
            muted: this.target.muted,
            is_enabled:
                !this.isEdit && this.startDisabled
                    ? 0
                    : !this.isEdit && !this.startDisabled
                    ? 1
                    : this.target.is_enabled,
            media_streams: streamsInUse,
            vpcs: vpcInUse
        };

        if (this.isEdit) {
            const objects = { resource_tag_ids: { objectsKey: "resourceTags", valuePath: "id" } };
            const changedData = this.sharedService.getZixiObjDiff(model, this.existingTarget, [], objects);
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedTarget = await this.ts.updateTarget(this.existingTarget, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedTarget;
                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "TARGET",
                        async () => {
                            const updateAndRestartTarget = await this.ts.updateTarget(this.target, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartTarget) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart jpegxs target", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(
                                    urlBuilder.getRegularTargetUrl(
                                        this.targetId,
                                        Constants.urls.targetTypes.jpegxs,
                                        model.name
                                    )
                                );
                            } else this.saving = false;
                        },
                        this.target.name
                    );
                    this.saving = false;
                } else if (updatedTarget) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update jpegxs target target", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(
                        urlBuilder.getRegularTargetUrl(this.targetId, Constants.urls.targetTypes.jpegxs, model.name)
                    );
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate(
                    urlBuilder.getRegularTargetUrl(this.targetId, Constants.urls.targetTypes.jpegxs, model.name)
                );
            }
        } else {
            const result = await this.ts.addTarget(model, TargetApiType.JPEGXS);
            if (result) {
                this.saving = false;
                this.mixpanelService.sendEvent("create jpegxs target");
                this.router.navigate(
                    urlBuilder.getRegularTargetUrl(result.id, Constants.urls.targetTypes.jpegxs, model.name)
                );
            } else this.saving = false;
        }
    }

    cancel() {
        if (this.isEdit || this.isClone)
            this.router.navigate(
                urlBuilder.getRegularTargetUrl(this.targetId, Constants.urls.targetTypes.jpegxs, this.targetName)
            );
        else this.router.navigate([Constants.urls.targets]);
    }

    back() {
        this.location.back();
    }

    flowSelected() {
        this.channel = this.channels.find(ch => ch.id === this.selectedChannelID);
        this.VPCCompositionChanged();
        this.mediaStreamConfigChanged();
        if (!this.channel) {
            this.mediaStreams = [];
            this.vpc = [];
            this.mediaStreamConfigChanged();
            this.VPCCompositionChanged();
            return;
        }

        this.mediaStreams = this.channel.mediaStreams.map(ms => ({ ...ms, port: null, inUse: false }));
        this.vpc = this.channel.vpc.map(v => ({ ...v, inUse: false }));
        this.awsAccountRegionsLoading = true;
        this.awsIAMRolesLoading = true;
        for (const strm of this.mediaStreams) {
            targetUpdated(strm, this.target);
        }

        this.updateAWSAccount().then(() => {
            return this.updateAWSRegion();
        });

        this.mediaStreamConfigChanged();
        this.VPCCompositionChanged();
    }

    async updateAWSAccount() {
        this.awsRegions = [];

        if (this.channel.aws_account_id == null) return;

        await this.updateAWSIAMRoles();

        this.awsAccountRegionsLoading = true;

        const regions = await this.aas.getAWSAccountRegions(this.channel.aws_account_id);
        if (regions) {
            const awsRegions = [];
            Object.entries(regions).forEach(([key, value]) => {
                awsRegions.push({ id: key, name: value });
            });
            this.awsRegions = awsRegions;
        }

        this.awsAccountRegionsLoading = false;
    }

    async updateAWSRegion() {
        this.awsZones = [];

        if (this.channel.aws_account_id == null || this.channel.region == null) return;

        this.awsAccountZonesLoading = true;

        const region = await this.aas.getAWSRegionDetails(this.channel.aws_account_id, this.channel.region);
        this.awsZones = [
            ...region.zones.map(r => {
                return { id: r.name, name: r.name };
            })
        ];

        this.awsSecurityGroups = region.security_groups;
        this.awsSubnets = region.subnets.map(subnet => ({
            ...subnet,
            name: `${subnet.vpc_id} - ${subnet.id} - ${subnet.cidr} - ${subnet.az}`
        }));
        this.updateAWSZone();

        this.awsAccountZonesLoading = false;
    }

    async updateAWSIAMRoles() {
        this.awsIAMRoles = [];

        if (this.channel.aws_account_id == null) return;

        this.awsIAMRolesLoading = true;

        this.awsIAMRoles = (await this.aas.getAWSAccountIAMRoles(this.channel.aws_account_id)).filter(
            role => role.mediaconnect
        );

        this.awsIAMRolesLoading = false;
    }

    updateAWSZone() {
        this.awsSubnetOptions =
            this.channel.availability_zone !== "random"
                ? this.awsSubnets.filter(subnet => subnet.az === this.channel.availability_zone)
                : this.awsSubnets;
    }

    VPCCompositionChanged() {
        if (!this.channel) this.VPCSelectionProblem = false;
        this.VPCSelectionProblem = this.vpc.filter(v => v.inUse).length < 2;
    }

    mediaStreamConfigChanged() {
        this.mediaStreamPorts = this.mediaStreams.filter(ms => ms.inUse).map(ms => ms.port);

        if (!this.channel) this.mediaStreamSelectionProblem = false;
        this.mediaStreamSelectionProblem = this.mediaStreams.filter(ms => ms.inUse).length === 0;
    }

    JPEGXSCompatibleFlow(): (MediaConnectFlow) => boolean {
        return this.filterFlows;
    }

    filterFlows(flow: MediaConnectFlow): boolean {
        return (
            (!flow.udpRtp || flow.udpRtp.length === 0) &&
            (!flow.zixiPull || flow.zixiPull.length === 0) &&
            (!flow.zixiPush || flow.zixiPush.length === 0) &&
            (!flow.source || ["cdi", "st2110-jpegxs"].includes(flow.source.protocol))
        );
    }
}
