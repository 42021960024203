import { Component, OnInit, OnDestroy, inject } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { SharedService } from "src/app/services/shared.service";
import { TargetsService } from "../targets.service";
import { ChannelsService } from "../../channels/channels.service";
import { MediaConnectFlow } from "src/app/models/shared";
import { MediaconnectCDITarget, TargetApiType } from "../../channels/channel";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "../../../services/title.service";
import {
    FullMediaconnectMediaStream,
    targetUpdated
} from "../../../components/shared/zx-mediaconnect-mediastream/zx-mediaconnect-mediastream.component";
import { UntypedFormControl, Validators } from "@angular/forms";
import { urlBuilder } from "@zixi/shared-utils";

@Component({
    selector: "app-target-cdi-form",
    templateUrl: "./target-cdi-form.component.html"
})
export class TargetCDIFormComponent implements OnInit, OnDestroy {
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private sharedService = inject(SharedService);
    private modalService = inject(ModalService);
    private cs = inject(ChannelsService);
    private ts = inject(TargetsService);
    private mixpanelService = inject(MixpanelService);
    private titleService = inject(TitleService);
    private location = inject(Location);
    target: MediaconnectCDITarget;
    targetId: number;
    targetName: string;
    targetNames: string[];
    existingTarget: MediaconnectCDITarget;
    action: string;

    type: string;
    subtype: string;
    id: string;

    ingestURLs: string[];

    showAdvanced = false;
    loading = true;
    saving = false;
    submitted = false;
    minLength = 2;
    isEdit = false;
    isClone = false;
    startDisabled = false;

    channel: MediaConnectFlow;
    channels: MediaConnectFlow[];
    selectedVPC: string = null;
    channelRegion: string;
    mediaStreams: FullMediaconnectMediaStream[] = [];

    mediaStreamSelectionProblem = false;

    constants = Constants;

    private targetsSubscription: Subscription;
    private channelsSubscription: Subscription;

    selectedChannelID: number;

    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.name),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);

    prepForm() {
        if (!this.target && this.action) {
            return;
        }

        if (this.action) {
            this.tagsControl.setValue(this.target.resourceTags);
            if (this.isEdit) {
                this.nameControl.setValue(this.target.name);
            }

            if (this.isClone) {
                this.target.name = "";
                this.target.muted = this.target.active_mute ? 1 : 0;
            }
            this.selectedChannelID = this.selectedChannelID ?? this.target.mediaconnect_flow_id;
        }

        if (!this.target && !this.isClone && !this.isEdit) {
            this.resetForm();
        }
        if (this.target && this.selectedChannelID && this.channels) {
            this.channel = this.channels.find(c => c.id === this.selectedChannelID);
            this.flowSelected();

            for (const ms of this.target.mediaStreams ?? []) {
                const theStream = this.mediaStreams.find(strm => strm.name === ms.name);
                if (theStream) {
                    theStream.inUse = true;
                    theStream.encoding_name = ms.encoding_name;
                }
            }

            this.mediaStreamConfigChanged();
        }

        if (!this.channel) {
            this.selectedChannelID = null;
        }
    }

    resetForm() {
        this.tagsControl.setValue([]);
        this.nameControl.setValue(null);

        // Channel
        this.target = new MediaconnectCDITarget();
    }

    async ngOnInit() {
        const params = this.route.snapshot.paramMap;
        if (params.get("targetId")) this.targetId = urlBuilder.decode(params.get("targetId"));
        this.targetName = params.get("name");
        this.action = params.get("action");

        this.type = params.get("type");
        this.subtype = params.get("subtype");
        this.id = params.get("id");

        if (this.action === "edit") this.isEdit = true;
        if (this.action === "clone") this.isClone = true;

        let anyTarget = null;

        if (this.targetId) {
            anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.CDI));

            // Check if target found in cache, if not get targets and target
            if (this.sharedService.isEmptyObject(anyTarget)) {
                await this.ts.refreshTargets(TargetApiType.CDI).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.CDI));
                await this.ts.refreshTarget(TargetApiType.CDI, anyTarget.objId, true).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.CDI));
            } else {
                if (!anyTarget.target.hasFullDetails) {
                    await this.ts.refreshTarget(TargetApiType.CDI, anyTarget.objId, true).toPromise();
                    anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.CDI));
                }
            }
        }
        this.loading = false;

        if (anyTarget && anyTarget.target instanceof MediaconnectCDITarget) {
            this.target = anyTarget.target;
            this.existingTarget = _.cloneDeep(this.target);
        }

        //  Channels
        this.cs.getMediaConnectFlows(true);
        this.channelsSubscription = this.cs.mediaconnectFlows.subscribe((channels: MediaConnectFlow[]) => {
            this.channels = channels.filter(this.filterFlows);
        });

        if (this.type === "channel" && this.subtype && this.id) {
            if (this.subtype === "mediaconnect") {
                this.selectedChannelID = parseInt(this.id, 10);
            }
        }

        this.ts.getAllTargets();

        this.targetsSubscription = this.ts.targets.subscribe(targets => {
            this.targetNames = _.map(
                _.filter(targets, t => t.apiType === TargetApiType.CDI),
                "target.name"
            );
            this.ingestURLs = _.map(targets, "target.ingest_url");

            if (this.isEdit) this.targetNames = _.without(this.targetNames, this.targetName);
        });

        // Set Title
        this.titleService.setTitle("TARGET", this.action, this.target);
        this.prepForm();
    }

    ngOnDestroy() {
        this.targetsSubscription.unsubscribe();
        this.channelsSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        //  Expecting these to be handled by validators, so far these are not, perhaps worth leaving
        //  the check anyway.
        const streamsInUse: FullMediaconnectMediaStream[] = !this.channel
            ? []
            : this.mediaStreams
                  .filter(ms => ms.inUse)
                  .map(strm => {
                      const { encoding_profile, encoding_compression, ...essentials } = strm;
                      encoding_profile;
                      encoding_compression;
                      return essentials;
                  });
        if (this.channel && this.mediaStreamSelectionProblem) {
            this.saving = false;
            return;
        }

        const model = {
            name: this.nameControl.value,
            resource_tag_ids: _.map(this.tagsControl.value, "id"),
            alerting_profile_id: this.target.alertingProfile.id,
            type: "cdi",
            destination_ip: this.target.destination_ip,
            destination_port: this.target.destination_port,
            mediaconnect_flow_id: this.selectedChannelID ?? null,
            muted: this.target.muted,
            is_enabled:
                !this.isEdit && this.startDisabled
                    ? 0
                    : !this.isEdit && !this.startDisabled
                    ? 1
                    : this.target.is_enabled,
            media_streams: streamsInUse,
            vpcs: !this.channel ? [] : this.channel.vpc.filter(v => v.network_interface_type === "efa")
        };

        if (this.isEdit) {
            const objects = { resource_tag_ids: { objectsKey: "resourceTags", valuePath: "id" } };
            const changedData = this.sharedService.getZixiObjDiff(model, this.existingTarget, [], objects);
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedTarget = await this.ts.updateTarget(this.existingTarget, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedTarget;
                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "TARGET",
                        async () => {
                            const updateAndRestartTarget = await this.ts.updateTarget(this.existingTarget, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartTarget) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart cdi target", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(
                                    urlBuilder.getRegularTargetUrl(
                                        this.targetId,
                                        Constants.urls.targetTypes.cdi,
                                        model.name
                                    )
                                );
                            } else this.saving = false;
                        },
                        this.target.name
                    );
                    this.saving = false;
                } else if (updatedTarget) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update cdi target", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(
                        urlBuilder.getRegularTargetUrl(this.targetId, Constants.urls.targetTypes.cdi, model.name)
                    );
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate(
                    urlBuilder.getRegularTargetUrl(this.targetId, Constants.urls.targetTypes.cdi, model.name)
                );
            }
        } else {
            const result = await this.ts.addTarget(model, TargetApiType.CDI);
            if (result) {
                this.saving = false;
                this.mixpanelService.sendEvent("create cdi target");
                this.router.navigate(
                    urlBuilder.getRegularTargetUrl(result.id, Constants.urls.targetTypes.cdi, model.name)
                );
            } else this.saving = false;
        }
    }

    cancel() {
        if (this.isEdit || this.isClone)
            this.router.navigate(
                urlBuilder.getRegularTargetUrl(this.targetId, Constants.urls.targetTypes.cdi, this.targetName)
            );
        else this.router.navigate([Constants.urls.targets]);
    }

    back() {
        this.location.back();
    }

    flowSelected() {
        this.channel = this.channels.find(ch => ch.id === this.selectedChannelID);
        this.mediaStreamConfigChanged();
        if (!this.channel) {
            this.mediaStreams = [];
            this.mediaStreamConfigChanged();
            this.selectedVPC = null;
            return;
        }

        this.mediaStreams = this.channel.mediaStreams.map(ms => ({ ...ms, port: null, inUse: false }));
        for (const strm of this.mediaStreams) {
            targetUpdated(strm, this.target);
        }

        this.mediaStreamConfigChanged();
        const theChannel = this.channel;
        const vpcs = theChannel.vpc;
        const efaVPC = vpcs.find(v => v.network_interface_type === "efa");
        this.selectedVPC = efaVPC?.name ?? null;
    }

    mediaStreamConfigChanged() {
        if (!this.channel) this.mediaStreamSelectionProblem = false;
        this.mediaStreamSelectionProblem = this.mediaStreams.filter(ms => ms.inUse).length === 0;
    }

    flowSupportsCDITarget(): (MediaConnectFlow) => boolean {
        return this.filterFlows;
    }

    filterFlows(flow: MediaConnectFlow): boolean {
        return (
            (!flow.udpRtp || flow.udpRtp.length === 0) &&
            (!flow.zixiPull || flow.zixiPull.length === 0) &&
            (!flow.zixiPush || flow.zixiPush.length === 0) &&
            (!flow.source || ["cdi", "st2110-jpegxs"].includes(flow.source.protocol)) &&
            !!flow.vpc.find(v => v.network_interface_type === "efa")
        );
    }
}
