import { Location } from "@angular/common";
import { Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TitleService } from "../../../services/title.service";

import { Constants } from "../../../constants/constants";
import { Tag } from "src/app/models/shared";
import { SharedService } from "src/app/services/shared.service";
import { take } from "rxjs";
import { OBJECT_TYPE } from "../../networks/network-objects-state/network-objects-state.service";

@Component({
    selector: "app-target-form",
    templateUrl: "./target-form.component.html"
})
export class TargetFormComponent implements OnInit {
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private titleService = inject(TitleService);
    private location = inject(Location);
    private sharedService = inject(SharedService);
    type: string;
    subtype: string;
    id: string;
    constants = Constants;

    httpTags: Tag[] = [];
    pullTags: Tag[] = [];
    pushTags: Tag[] = [];
    udprtpTags: Tag[] = [];
    rtmpTags: Tag[] = [];
    ristTags: Tag[] = [];
    srtTags: Tag[] = [];
    ndiTags: Tag[] = [];
    cdiTags: Tag[] = [];
    jpegxsTags: Tag[] = [];
    medialiveTags: Tag[] = [];
    entitlementTags: Tag[] = [];
    mediaconnectTags: Tag[] = [];

    ngOnInit() {
        // Set Title
        this.titleService.setTitle("TARGET");

        const params = this.route.snapshot.paramMap;
        this.type = params.get("type");
        this.subtype = params.get("subtype");
        this.id = params.get("id");
        // Resource Tags
        this.sharedService
            .getResourceTagsByType(OBJECT_TYPE.HTTP_PUBLISHING_TARGET)
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.httpTags = tags;
            });

        this.sharedService
            .getResourceTagsByType(OBJECT_TYPE.ZIXI_PULL_TARGET)
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.pullTags = tags;
            });

        this.sharedService
            .getResourceTagsByType(OBJECT_TYPE.ZIXI_PUSH_TARGET)
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.pushTags = tags;
            });

        this.sharedService
            .getResourceTagsByType(OBJECT_TYPE.UDP_RTP_TARGET)
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.udprtpTags = tags;
            });

        this.sharedService
            .getResourceTagsByType(OBJECT_TYPE.RTMP_PUSH_TARGET)
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.rtmpTags = tags;
            });

        this.sharedService
            .getResourceTagsByType(OBJECT_TYPE.RIST_TARGET)
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.ristTags = tags;
            });

        this.sharedService
            .getResourceTagsByType(OBJECT_TYPE.SRT_TARGET)
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.srtTags = tags;
            });

        this.sharedService
            .getResourceTagsByType(OBJECT_TYPE.NDI_TARGET)
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.ndiTags = tags;
            });

        this.sharedService
            .getResourceTagsByType("mediaconnect_cdi_targets")
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.cdiTags = tags;
            });

        this.sharedService
            .getResourceTagsByType("mediaconnect_jpegxs_targets")
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.jpegxsTags = tags;
            });

        this.sharedService
            .getResourceTagsByType("medialive_http_targets")
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.medialiveTags = tags;
            });

        this.sharedService
            .getResourceTagsByType("mediaconnect_entitlement_targets")
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.entitlementTags = tags;
            });

        this.sharedService
            .getResourceTagsByType("mediaconnect")
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.mediaconnectTags = tags;
            });
    }

    goto(type) {
        if (this.type && this.subtype && this.id)
            this.router.navigate([Constants.urls.targets, type, "new", this.type, this.subtype, this.id]);
        else this.router.navigate([Constants.urls.targets, type, "new"]);
    }

    cancel() {
        this.location.back();
    }
}
